import Slide from 'react-reveal/Slide';

function Community(){
  return(
    <div className="bg-white h-full pt-28 pb-28">
      <div className="text-purple-900 text-3xl text-center h-1/3">Community Experience</div>
      <p className="text-center pt-2 text-purple-900 pl-6 pr-6">
        Being part of these organisations has greatly grown
        my soft and technical skills in software development.</p>

      
      <div className="flex grid gap-0 md:grid-cols-3 sm:grid-cols-1 md:grid-flow-col md:p-0 text-black text-center items-center justify-center">
        

        <Slide bottom>
          <div className="flex items-center justify-center grid grid-flow-row pt-40 mx-auto w-1/2">
            <div className="bg-purple-900 rounded-full h-24 w-24 flex items-center justify-center text-center text-white mx-auto">RG</div>
            <div className="pt-8 text-center">
              <h2 className="text-xl">
                <a className="text-purple-500" href="http://railsgirls.com/mombasa.html">Rails Girls Kenya</a></h2>
                <p className="text-sm text-gray-700">January, 2018 to February, 2021</p>
                <p className="text-black pt-8 pb-24">
                  At Rails Girls I was part of the team
                  that provided mentorship and organized
                  technology events in Mombasa and
                  Nairobi, Kenya.
                </p>
            </div>
          </div>
        </Slide>

        <Slide bottom>
        <div className="flex items-left justify-left grid grid-flow-row pt-40 mx-auto w-1/2">
          <div className="bg-purple-900 rounded-full h-24 w-24 flex items-center justify-center text-center text-white mx-auto">ALC</div>
          <div className="pt-8 text-center">
            <h2 className="text-xl ">
              <a className="text-purple-500" href="https://andela.com/alc/">Andela Learning Community</a>
              <p className="text-sm text-gray-700">May, 2018 to February, 2021</p>
              </h2>
            <p className="text-black pt-8 pb-24 ">
              With Andela Learning Community<br></br> 
              I was honored to be part of the team that provided <br></br>
              mentorship in software developement  <br></br>
              and gave talks during events<br></br>
            </p>
          </div>
        </div>
        </Slide>
        
        <Slide bottom>
        <div className="flex items-left justify-left grid grid-flow-row pt-40 mx-auto w-1/2">
          <div className="bg-purple-900 rounded-full h-24 w-24 flex items-center justify-center text-center text-white mx-auto">TJ</div>
          <div className="pt-8 text-center">
            <h2 className="text-xl text-purple-500">T-Ajiri</h2>
            <p className="text-sm text-gray-700">2018</p>
            <p className="text-black pt-8 pb-24 ">
              T-Ajiri allowed me to take tech<br></br> 
              to orphanages in order to help the kids get <br></br>
              equipped with basic technological skills.
            </p>
          </div>
        </div>
        </Slide>
      </div>
    </div>
  )
}

export default Community;