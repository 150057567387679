import Home from './components/homepage/home';
import Bio from './components/biopage/bio';
import Experience from './components/experiencepage/experience';
import Community from './components/communitypage/community';
import Contact from './components/contact/contact';
import './App.css';

function App() {
  return (
    <div className="App">
      <Home />
      <Bio />
      <Experience />
      <Community />
      <Contact />
    </div>
    
  );
}

export default App;
