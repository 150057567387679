import Lottie from 'react-lottie';
import * as animationData from '../../assets/images/animation/desktop.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import * as roundData from '../../assets/images/animation/round.json';
import Slide from 'react-reveal/Slide';

function Contact(){
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData.default,
  }

  const roundOptions = {
    loop: true,
    autoplay: true, 
    animationData: roundData.default,
  }

  return(
    <div className="main contact bg-black h-screen">
      <div className="grid md:grid-cols-2 grid-cols-1 h-screen">
        <Slide bottom>
          <div className="main">
            <Lottie options={defaultOptions}
              height={300}
              width={300}
              />
          </div>
        </Slide>
        
        <div className="bg-purple-300">
          <Slide bottom>
            <div>
            <Lottie options={roundOptions}
              height={300}
              width={300}
              />
            </div>
          
          

          <h2 className="text-purple-900 md:pt-16 text-3xl">
          Get in touch with me! </h2>

          <div className="flex items-center justify-center grid grid-flow-row pt-40 mx-auto w-1/2 grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mb-40 sm:overflow-scroll md:overflow-hidden">
            <div className="pt-6">
            <FontAwesomeIcon icon={faLinkedin} color="white" size="lg" />
              <h6 className="text-purple-900">
                <a href="https://www.linkedin.com/in/susanwere/">LinkedIn</a></h6>
            </div>
            <div className="pt-6">
              <FontAwesomeIcon icon={faGithub} color="white" size="lg" />
                <h6 className="text-purple-900">
                  <a href="https://github.com/susanwere">Github</a></h6>
            </div>
          </div>
          </Slide>
        </div>
      </div>
    </div>
  )
}

export default Contact;