import './bio.css';
import Slide from 'react-reveal/Slide';

function Bio () {

  const technologies = {
    1: ["RUBY/RAILS", 90, 700],
    2: ["JAVASCRIPT", 90, 700],
    3: ["J-QEURY", 90, 700],
    4: ["TYPESCRIPT", 80, 700],
    5: ["COFFEESCRIPT", 80, 700],
  }

  return (
    <div className="main bg-black h-full pt-24 pb-24 overflow-hidden">
      <div className="grid md:gap-4 md:grid-cols-2 sm:grid-cols-1 md:divide-x divide-purple-500 md:grid-flow-col">
        <Slide bottom>
        <div className="text-gray-500 bio-text flex items-center pb-16">
            <div className="w-2/3">
              <h2 className="bio-head text-4xl">Who I am?</h2>
              <br></br>
              <p className="">
                I am an experienced full stack software engineer with experience in a 
                fast-paced technology environment. I pride myself in delivering clean and 
                efficient software solutions
              </p>
              <br></br>
              <p>
                I am passionate about giving back to the community and therefore 
                been mentoring in Rails Girls and Andela Learning Community
              </p>
            </div>
         </div>
        </Slide>
         
        <Slide bottom>
        <div className="text-white relative">
                {Object.values(technologies).map((e, key) => 
                    (<div className="relative pt-1 pl-24 pr-24" key={key}>
                  <div className="flex mb-2 items-center justify-between ">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-600 bg-purple-200">
                        {e[0]}
                      </span>
                    </div>
                    <div className="text-right">
                      <span className="text-xs font-semibold inline-block text-purple-600">
                        
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                    <div style={{ width: `${e[1]}%` }} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-${e[2]}`}></div>
                  </div>
                </div>)
                )}
                
          </div>
        </Slide>
          
         
         
      </div>
    </div>
  )
}

export default Bio;