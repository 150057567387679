import "./experience.css";
import Slide from 'react-reveal/Slide';

function Experience() {
  return (
    <div className="experience h-full overflow-scroll pb-24">
        <h1 className="text-center text-4xl pb-24 pt-24  text-purple-900 relative">
          Work Experience
        </h1>

        <div className="flex items-center justify-center relative">
          <div className="md:bg-purple-500 rounded-full h-5 w-5 flex items-center justify-center text-center ml-5"></div>
        </div>
        
        <div className="flex grid gap-4 md:grid-cols-2 sm:grid-cols-1 md:divide-x-2 divide-purple-500 md:grid-flow-col text-right">
          <div className="pr-4">
            <Slide bottom>
              <h2 className="text-purple-900 text-xl">Software Engineer</h2>
              <h4 className="text-gray-700">MICROSOFT</h4>
              <h6 className="text-gray-700 text-sm">Working as part of the <br/> Planning and Tracking team at Github</h6>
              <br></br>
              <h4 className="text-purple-900">February, 2021 to Present</h4>
            </Slide>
          </div>
        <div>

        </div>
        
      </div>

      <div className="flex items-center justify-center">
        <div className="md:bg-purple-500 rounded-full h-5 w-5 flex items-center justify-center text-center ml-4"></div>
      </div>
 
      
      <div className="flex grid gap-4 md:grid-cols-2 sm:grid-cols-1 md:divide-x-2 divide-purple-500 md:grid-flow-col text-left">
        <div>
        </div>
        <div className="pl-5">
          <Slide bottom>
            <h2 className="text-purple-900 text-xl">Software Engineer</h2>
            <h4 className="text-gray-700">ANDELA KENYA</h4>
            <h6 className="text-gray-700 text-sm">Worked as a Contractor at <br/> Paylitix INC and INDIEGOGO</h6>
            <br></br>
            <h4 className="text-purple-900">October, 2018 to February, 2021</h4>
          </Slide>
        </div>
      </div>

      <div className="flex items-center justify-center">
        <div className="md:bg-purple-500 rounded-full h-5 w-5 flex items-center justify-center text-center ml-4"></div>
      </div>

      <div className="flex grid gap-4 md:grid-cols-2 sm:grid-cols-1 md:divide-x-2 divide-purple-500 md:grid-flow-col text-right">
        
        <div className="pr-4">
          <Slide bottom>
          <h2 className="text-purple-900 text-xl">INTERN</h2>
          <h4 className="text-gray-700">FEDHA ELECTRICS</h4>
          <br></br>
          <h4 className="text-purple-900">August, 2018 to September, 2018</h4>
          </Slide>
        </div>
        <div></div>
      </div>

      <div className="flex items-center justify-center">
        <div className="md:bg-purple-500 rounded-full h-5 w-5 flex items-center justify-center text-center ml-4"></div>
      </div>
    </div>
  )
}

export default Experience;