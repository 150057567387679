import './home.css'
import me from '../../assets/images/me.png';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/images/animation/lottieflies.json';
import * as scrollData from '../../assets/images/animation/scrolldown.json';
import React, { useState, useEffect } from 'react';

function Home() {

  const [isStopped, setIsStopped] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData.default,
  }

  const scrollOptions = {
    loop: true,
    autoplay: true, 
    animationData: scrollData.default,
  }

  useEffect(
    () => {
      let timer = setTimeout(() => setIsStopped(true), 2000)
      return () => {
        clearTimeout(timer)
      }
    },
    []
  )


  return (
    <div className="main bg-purple-300">
        <div className="relative bg-purple-300 overflow-hidden h-screen">
        <div className="max-w-7xl mx-auto h-screen">
          <div className="home-text mt-6 relative z-20 pb-8 bg-transparent sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
  
            <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-purple-50 sm:text-5xl md:text-8xl">
                  <span className="block xl:inline">Susan </span>
                  <span className="block text-purple-900 xl:inline">Ong'ayo </span>
                  <span className="block xl:inline">Were</span>
                </h1>
                <p className="mt-3 text-base text-purple-900 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Software Engineer <br/> Berlin, Germany
                </p>
              </div>
              <div className="object-none object-left-bottom md:w-0 sm:w-300 h-24 mb-2 pt-16">
                <Lottie options={scrollOptions}
                  height={250}
                  width={50}
                />
            </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:-inset-y-10 lg:right-0 lg:w-1/2">
          <div className={isStopped ? "hidden" : "main"}>
            <Lottie options={defaultOptions}
              height={400}
              width={400}
              isStopped={isStopped}
              />
          </div>
          <img className={
            isStopped ? 
            "animate-pulse h-screen w-full object-cover sm:h-full md:h-full lg:w-full lg:h-auto" : 
            "hidden" } src={me} alt="" />
        </div>
        </div>
      
    </div>
  );
}

export default Home;
